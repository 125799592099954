import React from 'react'
import Fade from 'react-reveal/Fade'
import { Link as ScrollLink } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'

const Solution = (props) => {
  const data = useStaticQuery(graphql`
    {
      publication: file(
        base: {
          eq: "Santa Maria PL et al. BMC Anesthesiology (2017) 17-102.pdf"
        }
      ) {
        publicURL
        name
      }
    }
  `)
  return (
    <section id="Solution" className="spotlight style4 top bottom fade">
      <Fade>
        <div className="content">
          <div className="container">
            <header>
              <h2>Flotherm Thermal Compression Device (TCD)</h2>
              <p>
                Flotherm TCD is a medical device that combines the power of
                dynamic compression with lower-limb warming to deliver greater
                control over perioperative safety. The TCD is the only{' '}
                <strong>non-invasive</strong> and{' '}
                <strong>non-surface area dependent</strong> solution to manage
                patient risk for IPH and DVT, driving perioperative compliance
                without changing existing surgical workflows.
              </p>
            </header>
            <div className="box alt">
              <div className="row gtr-uniform">
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-procedures"></span>
                  <h3>Patient Safety and Usability</h3>
                  <p>
                    Engineered to maintain normothermia while delivering venous
                    thromboembolism (VTE) prophylaxis in a single,
                    patient-friendly solution
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-tasks"></span>
                  <h3>Clinical Workflow Integration</h3>
                  <p>
                    Novel design intended to obviate the risk of unintended
                    surgical site contamination while preserving clinical
                    access.
                  </p>
                </section>
                <section className="col-4 col-6-medium col-12-xsmall">
                  <span className="icon solid alt major fa-thermometer-three-quarters"></span>
                  <h3>Clinical Evidence</h3>
                  <p>
                    Early clinical validation demonstrates superior management
                    of normothermia compared to forced air warming.
                  </p>
                </section>
              </div>
            </div>
            <footer className="major">
              <ul className="actions special">
                <li>
                  <a href={data.publication.publicURL} className="button">
                    Read our Published Results
                  </a>
                </li>
              </ul>
            </footer>
          </div>
        </div>
      </Fade>
      <ScrollLink
        to="Contact"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={0}
        duration={1500}
        spy={true}
      >
        Next
      </ScrollLink>
    </section>
  )
}

export default Solution
