import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'
import ClinicalNeed from '../components/ClinicalNeed'
import Market from '../components/Market'
import Solution from '../components/Solution'
import Contact from '../components/Contact'

class Home extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          title="Flotherm Thermal Compression Device for Hospitals"
          meta={[
            {
              name: 'description',
              content:
                'Flotherm is a medical device company building technology products that improve perioperative patient management.',
            },
            {
              name: 'keywords',
              content:
                'Flotherm, Thermal Compression, Flotherm TCD, Medical Device, Technology, VTE, DVT, Hypothermia, Temperature Control, Patient Monitoring, Perioperative, Normothermia',
            },
          ]}
        ></Helmet>
        <Banner />
        <ClinicalNeed />
        <Market />
        <Solution />
        <Contact />
      </Layout>
    )
  }
}

export default Home
