import React from 'react'
import Fade from 'react-reveal/Fade'

const Contact = (props) => (
  <section id="Contact" className="wrapper style2 special fade inactive">
    <Fade>
      <div className="container">
        <header>
          <h2>Contact Us</h2>
        </header>
        <p>
          If you would like to inquire about a partnership opportunity or learn
          more about Flotherm and our technology, please submit the form below
          or email us directly.
        </p>
        <form
          name="contact"
          className="cta"
          method="post"
          netlify-honeypot="bots-unwelcome"
          data-netlify="true"
        >
          <div className="row gtr-uniform gtr-50">
            <div className="col-6 col-12-xsmall">
              <input
                aria-label="Your name"
                type="text"
                name="name"
                id="name"
                placeholder="Your name"
                required
              />
            </div>
            <div className="col-6 col-12-xsmall">
              <input
                aria-label="Your email"
                type="email"
                name="email"
                id="email"
                placeholder="Your Email Address"
                required
              />
            </div>
            <div className="col-9 col-12-xsmall">
              <textarea
                aria-label="Message"
                name="message"
                id="message"
                placeholder="A Short Message"
                required
              />
            </div>
            <div className="hidden">
              This should not be shown to the user:{' '}
              <input name="bots-unwelcome" />
              <input type="hidden" name="form-name" value="contact" />
            </div>
            <div className="col-3 col-12-xsmall">
              <input
                aria-label="Submit"
                id="submitForm"
                type="submit"
                value="Submit"
                className="fit primary"
              />
            </div>
          </div>
          <div className="row gtr-uniform gtr-50">
            <div className="col-12 align-left">
              <p>
                General Inquiries:{' '}
                <a href="mailto:info@flothermtcd.com">info@flothermtcd.com</a>
              </p>
            </div>
          </div>
        </form>
      </div>
      <div className="container">
        <div className="row gtr-uniform">
          <div className="col-12 align-left">
            <div className="citations">
              <header>References</header>
              <ol>
                <li>
                  Díaz M, Becker DE.{' '}
                  <i>
                    Thermoregulation: Physiological and Clinical Considerations
                    during Sedation and General Anesthesia.
                  </i>{' '}
                  Anesthesia Prog, 2010;57(1)
                </li>
                <li>
                  John M et al.{' '}
                  <i>
                    Comparison of resistive heating and forced-air warming to
                    prevent inadvertent perioperative hypothermia
                  </i>{' '}
                  Br. J Anaesth. 2016;116(2)
                </li>
                <li>
                  Torossian A et al.{' '}
                  <i>
                    Active perioperative patient warming using a self-warming
                    blanket (BARRIER EasyWarm) is superior to passive thermal
                    insulation: a multinational, multicenter, randomized trial.
                  </i>{' '}
                  J Clin Anesth. 2016;34
                  <br />
                </li>
                <li>
                  Forbes SS et al.{' '}
                  <i>
                    Evidence-based guidelines for prevention of perioperative
                    hypothermia.
                  </i>{' '}
                  J Am Coll Surg. 2009;209(4)
                </li>
                <li>
                  Weirich TL.{' '}
                  <i>
                    Hypothermia/Warming Protocols: Why Are They Not Widely Used
                    in the OR?
                  </i>{' '}
                  AORN J. 2008;87(2)
                </li>
                <li>
                  NCCNSC.{' '}
                  <i>
                    The Management of Inadvertent Perioperative Hypothermia in
                    Adults. Royal College of Nursing.
                  </i>{' '}
                  London (2008)
                </li>
                <li>
                  Technavio.{' '}
                  <i>Global Patient Warming Devices Market, 2017-2021.</i>{' '}
                  (2017)
                </li>
                <li>
                  Technavio.{' '}
                  <i>Global Compression Therapy Market, 2017-2021.</i> (2017)
                </li>
                <li>
                  Definitive Healthcare.{' '}
                  <i>
                    All-Payor Inpatient Procedures by ICD-10 Code, Outpatient
                    Procedures by HCPCS Code, and ASC Procedures by HCPCS Code.
                  </i>{' '}
                  (2017)
                </li>
              </ol>
              <p className="notify-too-small">
                Page citations are only visible on wider media screens.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  </section>
)
export default Contact
