import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { useStaticQuery, graphql } from 'gatsby'

const Banner = (props) => {
  const bannerQuery = useStaticQuery(graphql`
    query bannerQuery {
      banner: file(relativePath: { eq: "operating-room.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
            aspectRatio
            sizes
            srcSet
            src
          }
        }
      }
    }
  `)
  return (
    <section
      id="banner"
      style={{
        backgroundImage: `url(${bannerQuery.banner.childImageSharp.fluid.src})`,
      }}
    >
      <div className="content">
        <header>
          <h2>Smart Perioperative Patient Management</h2>
          <p>
            Flotherm is committed to enhancing surgical outcomes by <br />
            developing next-generation perioperative warming technologies.
          </p>
        </header>
      </div>
      <ScrollLink
        to="ClinicalNeed"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={0}
        duration={1500}
        spy={true}
      >
        Next
      </ScrollLink>
    </section>
  )
}

export default Banner
