import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'
import { useStaticQuery, graphql } from 'gatsby'
// import svgInfoVolumes from '../assets/images/infographics/us-procedure-volume.svg'
// import svgInfoHypoRisk from '../assets/images/infographics/hypothermia-risk.svg'
// import svgInfoTAM from '../assets/images/infographics/us-total-addressable-market.svg'
import Img from 'gatsby-image'

export const Infographic = graphql`
  fragment Infographic on File {
    childImageSharp {
      fluid(maxWidth: 280, maxHeight: 280) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
        aspectRatio
      }
    }
  }
`

const Market = (props) => {
  const infoQuery = useStaticQuery(graphql`
    query infoQuery {
      proc: file(relativePath: { eq: "infographics/us-procedure-volume.png" }) {
        ...Infographic
      }
      risk: file(relativePath: { eq: "infographics/hypothermia-risk.png" }) {
        ...Infographic
      }
      market: file(
        relativePath: { eq: "infographics/us-total-addressable-market.png" }
      ) {
        ...Infographic
      }
    }
  `)
  return (
    <section id="Market" className="spotlight style2 top bottom fade">
      <Fade>
        <div className="content">
          <div className="container">
            <header>
              <h2>Market Opportunity</h2>
              <p>
                Currently, no warming solution helps maintain normothermia in a
                timely manner without impeding surgical site access and
                increasing the risk of surgical site contamination.
              </p>
            </header>
            <div className="row" style={{ padding: '0em 2em' }}>
              <div className="col-4 col-6-xsmall">
                <span className="image fit info">
                  <Img
                    fluid={infoQuery.proc.childImageSharp.fluid}
                    alt="70M US Patients"
                  />
                </span>
              </div>
              <div className="col-4 col-6-xsmall">
                <span className="image fit info">
                  <Img
                    fluid={infoQuery.risk.childImageSharp.fluid}
                    alt="84% Risk of Hypotheria"
                  />
                </span>
              </div>
              <div className="col-4 col-6-xsmall">
                <span className="image fit info">
                  <Img
                    fluid={infoQuery.market.childImageSharp.fluid}
                    alt="USD 2.3B TAM"
                  />
                </span>
              </div>
            </div>
            <div className="row">
              <p>
                Over 100M patients annually undergo invasive or therapeutic
                surgical procedures at risk for developing inadvertent
                hypothermia. Patient warming and dynamic compression represent a{' '}
                <strong>$4.6 billion</strong> worldwide market with attractive
                growth rates and room for margin expansion.<sup>7,8</sup> In the
                U.S. alone, over <strong>58M procedures</strong> are at moderate
                to high risk for developing complications due to DVT and IPH.
                <sup>9</sup>
              </p>
              <p>
                Leading healthcare regulators and organizations like{' '}
                <a href="https://www.cms.gov/Medicare/Medicare-Fee-for-Service-Payment/HospitalAcqCond/Hospital-Acquired_Conditions">
                  <strong>CMS</strong>
                </a>
                ,{' '}
                <a href="https://www.nlg.nhs.uk/content/uploads/2017/12/IFP-0685.pdf">
                  <strong>NHS</strong>
                </a>
                ,{' '}
                <a href="https://www.nice.org.uk/Guidance/CG65">
                  <strong>NICE</strong>
                </a>
                , and{' '}
                <a href="https://www.who.int/gpsc/global-guidelines-web.pdf">
                  <strong>WHO</strong>
                </a>{' '}
                remain unequivocally focused on reducing these complications.
              </p>
            </div>
          </div>
        </div>
        <div
          className="container"
          style={{
            padding: '1em 3em',
          }}
        ></div>
      </Fade>
      <ScrollLink
        to="Solution"
        className="goto-next"
        activeClass="active"
        smooth={true}
        offset={0}
        duration={1500}
        spy={true}
      >
        Next
      </ScrollLink>
    </section>
  )
}

export default Market
