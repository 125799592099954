import React from 'react'
// import pic02 from '../assets/images/surgery.webp'
import { Link as ScrollLink } from 'react-scroll'
import Fade from 'react-reveal/Fade'

const ClinicalNeed = (props) => (
  <section id="ClinicalNeed" className="spotlight style1 top bottom fade">
    <Fade>
      <div className="content">
        <div className="container">
          <header>
            <h2>Unmet Clinical Need</h2>
            <p>
              Inadvertent Perioperative Hypothermia is the sudden drop of core
              temperature below 36C pre-, intra-, or post-operatively. A lack of
              preoperative warming combined with the vasodilatory effects of
              anesthesia leads to a precipitous reduction in core temperature
              that is often too difficult to remedy during surgery.
            </p>
          </header>

          <div className="row">
            <div className="col-4 col-12-medium">
              <h3>Causes</h3>
              <p>
                Patients are exposed to <strong>cold environments</strong>,{' '}
                <strong>surfaces</strong>, and <strong>liquids</strong>{' '}
                throughout surgical care.
              </p>
              <p>
                Loss of peripheral vascular tone due to{' '}
                <strong>anesthesia</strong> results in redistributive
                hypothermia – cold peripheral blood recirculating at rates of
                -2°C per hour.<sup>1</sup>
              </p>
            </div>
            <div className="col-4 col-12-medium">
              <h3>Prevalence</h3>
              <p>
                Despite existing interventions, there remains a{' '}
                <strong>
                  38-56% incidence of inadvertent perioperative hypothermia
                  (IPH)
                </strong>
                .<sup>2,3</sup>
              </p>
              <p>
                Approximately <strong>50%</strong> of patients are hypothermic
                prior to start of surgery and <strong>33%</strong> at end.
                <sup>4</sup>
              </p>
            </div>
            <div className="col-4 col-12-medium">
              <h3>Complications</h3>
              <p>
                IPH clinically linked to higher odds of <strong>SSI</strong>{' '}
                (4x), <strong>cardiac events</strong> (2.2x),{' '}
                <strong>mechanical ventilation</strong> (1.58x), and{' '}
                <strong>bleeding</strong> (1.2x), all drivers of{' '}
                <strong>preventable costs</strong>.<sup>5,6</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
    <ScrollLink
      to="Market"
      className="goto-next"
      activeClass="active"
      smooth={true}
      offset={0}
      duration={1500}
      spy={true}
    >
      Next
    </ScrollLink>
  </section>
)
export default ClinicalNeed
